import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";
import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebRadioButtonComponent } from "../common/selltowebradiobutton/SellToWebRadioButtonComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class VehicleConditionRouteComponent extends Component {
    accidentData = [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" }
    ];
    paintData = [
        { key: "Like New", value: "Like New", description: "Absolutely flawless paint, like you just drove off the dealership lot!" },
        { key: "Very Good", value: "Very Good", description: "Some minor imperfections, such as swirl marks or small dings and dents here and there. Your car may like to make friends with shopping carts." },
        { key: "Acceptable", value: "Acceptable", description: "Scratches or other damage longer than a dollar bill. Large dents bigger than a golf ball. May have numerous paint chips. Possible minor repairs needed from a previous accident." },
        { key: "Major Issues", value: "Major Issues", description: "Widespread damage where major repairs are needed." }
    ];
    wheelsData = [
        { key: "Like New", value: "Like New", description: "All 4 tires match and have at least 75% of tread remaining. No scrapes or damage on any wheel." },
        { key: "Very Good", value: "Very Good", description: "All 4 tires match and have at least 50% of tread remaining. Wheels may have minor curb scrapes shorter than a dollar bill." },
        { key: "Acceptable", value: "Acceptable", description: "Tires have less than 50% tread remaining or may not match. Wheels may have major curb scrapes longer than a dollar bill. Parallel parking makes you cry." },
        { key: "Major Issues", value: "Major Issues", description: "Wheels have major damage such as bends, requiring replacement. Gotta love those Bay Area potholes!" }
    ];
    windowsData = [
        { key: "Like New", value: "Like New", description: "No chips or cracks on the windshield or other windows. Headlights and taillights are completely clear and free of fading, discoloration, or damage." },
        { key: "Very Good", value: "Very Good", description: "Windshield may have minor pitting or repairable chips. Headlights and taillights may have minor pitting but are otherwise clear and free of fading, discoloration, or damage." },
        { key: "Acceptable", value: "Acceptable", description: "Noticeable chips or scratches on the windshield or other windows. Headlights and taillights may have fading or discoloration. Bugs are no longer in question as the culprit!" },
        { key: "Major Issues", value: "Major Issues", description: "Large cracks on the windshield, other windows may be missing or cracked. Headlights and taillights may have major damage requiring replacement." }
    ];
    upholsteryData = [
        { key: "Like New", value: "Like New", description: "Looks and feels like new. Your car passes the new car smell test, and the hanging trees don't count!" },
        { key: "Very Good", value: "Very Good", description: "Light wear, but no stains, rips, or other issues. Vehicle has never been smoked in." },
        { key: "Acceptable", value: "Acceptable", description: "Noticeable wear including small stains, rips, or other issues. Vehicle has never been smoked in. Perhaps a furry friend has taken over the back seat?" },
        { key: "Major Issues", value: "Major Issues", description: "Major damage including large stains, rips, or other issues. Heavy odors, including any sign of smoke. Roll those windows down, man!" }
    ];
    interiorData = [
        { key: "Like New", value: "Like New", description: "No functional or cosmetic issues, everything works as expected!" },
        { key: "Very Good", value: "Very Good", description: "Everything works as expected, but there may be minor cosmetic issues. Your favorite button or switch has your fingerprint permanently ingrained." },
        { key: "Acceptable", value: "Acceptable", description: "Minor functional issues and/or larger cosmetic issues. “Don't push that button!” is a sentence you frequent." },
        { key: "Major Issues", value: "Major Issues", description: "Major functional issues or damaged items." }
    ];
    mechanicalData = [
        { key: "Like New", value: "Like New", description: "Runs and drives like new with no issues whatsoever. Vehicle is unmodified." },
        { key: "Very Good", value: "Very Good", description: "Runs and drives with no major issues. A simple warning light is OK, such as low fluids or an overdue service. Vehicle is unmodified." },
        { key: "Acceptable", value: "Acceptable", description: "May have minor faults or need repairs to the powertrain, suspension, brakes, or similar. Vehicle may have minor modifications." },
        { key: "Major Issues", value: "Major Issues", description: "May have major issues requiring immediate repair. Must still be drivable and able to be smogged in California." }
    ];
    keysData = [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" }
    ];

    constructor(props) {
        super(props);

        this.state = {
            vehicleDetailsData: null,
            selectedVCAccidents: "",
            selectedVCPaint: "",
            selectedVCWheels: "",
            selectedVCWindows: "",
            selectedVCUpholstery: "",
            selectedVCInterior: "",
            selectedVCMechanical: "",
            selectedVCKeys: "",
            isYourDetailsClicked: false,
            showSpinner: false,
            redirect: false
        }

        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onRadioButtonListChange = this.onRadioButtonListChange.bind(this);
        this.onYourDetailsClick = this.onYourDetailsClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    pageTitle = "Vehicle Condition";

    componentDidMount() {
        let vehicleDetailsData = CommonService.getSessionStorage("vehicleDetailsData");
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-condition", pageType: "Consumer", isLoggedIn: "No", properties: { vin: vehicleDetailsData?.vin, vehicleId: vehicleDetailsData?.selectedTrim?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            if (!vehicleDetailsData) {
                CommonService.clientAzureStorageLog("VehicleConditionRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId + " " + vehicleDetailsData?.vin, null, null, null, this.props.applicationInsights);
                this.setState({ showSpinner: false, redirectTo: "/unique-vehicle" });
            }
            else {
                window.scrollTo(0, 0);
                //Get Local Storage Data
                let vehicleId = vehicleDetailsData.selectedTrim.vehicleId;
                let lsAccident = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Accident");
                let lsPaint = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Paint");
                let lsWheels = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Wheels");
                let lsWindow = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Window");
                let lsUpholstery = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Upholstery");
                let lsInterior = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Interior");
                let lsMechanical = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Mechanical");
                let lsKeys = CommonService.getLocalStorage("VehicleCondition" + vehicleId + "Keys");

                this.setState({
                    selectedVCAccidents: lsAccident,
                    selectedVCPaint: lsPaint,
                    selectedVCWheels: lsWheels,
                    selectedVCWindows: lsWindow,
                    selectedVCUpholstery: lsUpholstery,
                    selectedVCInterior: lsInterior,
                    selectedVCMechanical: lsMechanical,
                    selectedVCKeys: lsKeys,
                    vehicleDetailsData: vehicleDetailsData
                });
            }
        }
        catch (error) {
            console.logError(error, "VehicleConditionRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId + " " + vehicleDetailsData?.vin);
        }
    }

    onRadioButtonListChange(id, item) {
        try {
            this.setState({ [id]: item.value });
        }
        catch (error) {
            console.logError(error, "VehicleConditionRouteComponent", "onRadioButtonListChange", id);
        }
    }

    onYourDetailsClick() {
        try {
            let inputsValid = this.state.selectedVCAccidents && this.state.selectedVCKeys && this.state.selectedVCPaint && this.state.selectedVCWheels && this.state.selectedVCWindows && this.state.selectedVCUpholstery && this.state.selectedVCInterior && this.state.selectedVCMechanical;
            if (inputsValid) {
                let vehicleDetailsData = this.state.vehicleDetailsData;
                let minimumSelectedCondition = CommonService.getMinimumSelectedCondition(this.state.selectedVCPaint, this.state.selectedVCWheels, this.state.selectedVCWindows, this.state.selectedVCUpholstery, this.state.selectedVCInterior, this.state.selectedVCMechanical);
                let vehicleConditionData = {
                    accidentResponse: this.state.selectedVCAccidents,
                    paintBody: this.state.selectedVCPaint,
                    wheelsTires: this.state.selectedVCWheels,
                    windowsLights: this.state.selectedVCWindows,
                    upholstery: this.state.selectedVCUpholstery,
                    interiorEquipment: this.state.selectedVCInterior,
                    mechanical: this.state.selectedVCMechanical,
                    keys: this.state.selectedVCKeys,
                    minimumSelectedCondition: minimumSelectedCondition
                };
                vehicleDetailsData["vehicleConditionData"] = vehicleConditionData;
                CommonService.setSessionStorage("vehicleDetailsData", vehicleDetailsData);
                let offerSession = CommonService.getSessionStorage("offerSession");
                offerSession.vehicleHistory.accidentResponse = vehicleConditionData.accidentResponse;
                offerSession.vehicleCondition = {};
                offerSession.vehicleCondition.paintBody = vehicleConditionData.paintBody;
                offerSession.vehicleCondition.windowsLights = vehicleConditionData.windowsLights;
                offerSession.vehicleCondition.wheelsTires = vehicleConditionData.wheelsTires;
                offerSession.vehicleCondition.upholstery = vehicleConditionData.upholstery;
                offerSession.vehicleCondition.interiorEquipment = vehicleConditionData.interiorEquipment;
                offerSession.vehicleCondition.mechanical = vehicleConditionData.mechanical;
                offerSession.vehicleCondition.keys = vehicleConditionData.keys;
                CommonService.setSessionStorage("offerSession", offerSession);
                //Set Local Storage
                let vehicleId = this.state.vehicleDetailsData.selectedTrim.vehicleId;
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Accident", this.state.selectedVCAccidents);
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Paint", this.state.selectedVCPaint);
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Wheels", this.state.selectedVCWheels);
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Window", this.state.selectedVCWindows);
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Upholstery", this.state.selectedVCUpholstery);
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Interior", this.state.selectedVCInterior);
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Mechanical", this.state.selectedVCMechanical);
                CommonService.setLocalStorage("VehicleCondition" + vehicleId + "Keys", this.state.selectedVCKeys);
            }
            else {
                this.scrollToTop();
            }

            this.setState({
                redirect: inputsValid,
                isYourDetailsClicked: true
            });
        }
        catch (error) {
            console.logError(error, "VehicleConditionRouteComponent", "onYourDetailsClick");
        }
    }

    onBackButtonClick() {
        try {
            window.history.go(-1);
        }
        catch (error) {
            console.logError(error, "VehicleConditionRouteComponent", "onBackButtonClick");
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.onYourDetailsClick();
        }
    }

    scrollToTop() {
        let scrollToElement = null;
        if (!this.state.selectedVCAccidents) {
            scrollToElement = this.vcAccidentsContainer;
        }
        else if (!this.state.selectedVCPaint) {
            scrollToElement = this.vcPaintContainer;
        }
        else if (!this.state.selectedVCWheels) {
            scrollToElement = this.vcWheelsContainer;
        }
        else if (!this.state.selectedVCWindows) {
            scrollToElement = this.vcWindowsContainer;
        }
        else if (!this.state.selectedVCUpholstery) {
            scrollToElement = this.vcUpholsteryContainer;
        }
        else if (!this.state.selectedVCInterior) {
            scrollToElement = this.vcInteriorContainer;
        }
        else {
            scrollToElement = this.vcMechanicalContainer;
        }

        if (scrollToElement) {
            let scrollElement = ReactDOM.findDOMNode(scrollToElement)
            let offsetPosition = scrollElement.getBoundingClientRect().top + window.pageYOffset - 60;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={"/your-details"} />;
        }

        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Condition", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Condition", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Condition", "Default", "Body Html")?.Value ?? "";
        return <div className="ac-site ac-vehiclecondition">
            <header className="ac-site-header ac-vehiclecondition-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={4} />
            <SellToWebTopPanelComponent imageUrl={this.state.vehicleDetailsData?.imageUrl} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} trim={this.state.vehicleDetailsData?.selectedTrim ? this.state.vehicleDetailsData.selectedTrim.trimName : "--"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-vehiclecondition-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="ac-vehiclecondition-form" onKeyDown={(event) => this.onKeyDown(event)} tabIndex={-1}>
                        <div ref={refElement => this.vcAccidentsContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCAccidents ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>
                                Accidents
                                {
                                    this.state.isYourDetailsClicked ? !this.state.selectedVCAccidents
                                        ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span>
                                        : "" : ""
                                }
                            </h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Has your vehicle been in any accidents?</p>
                            <SellToWebRadioButtonComponent id="selectedVCAccidents" name="rblAccident" data={this.accidentData} itemKey="value" value={this.state.selectedVCAccidents ? this.state.selectedVCAccidents : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                        </div>
                        <hr className="consumer-vehiclecondition-seperator" />

                        <div ref={refElement => this.vcPaintContainer = refElement} >
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCPaint ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Paint and Body  {this.state.isYourDetailsClicked ? !this.state.selectedVCPaint ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of bumpers, fenders, doors, roof, hood and trunk</p>
                            <SellToWebRadioButtonComponent id="selectedVCPaint" name="rblPaint" data={this.paintData} itemKey="value" value={this.state.selectedVCPaint ? this.state.selectedVCPaint : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div >
                        <div ref={refElement => this.vcWheelsContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCWheels ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Wheels and Tires  {this.state.isYourDetailsClicked ? !this.state.selectedVCWheels ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of rim, spokes, hub caps, tire tread, and tire age.</p>
                            <SellToWebRadioButtonComponent id="selectedVCWheels" name="rblWheels" data={this.wheelsData} itemKey="value" value={this.state.selectedVCWheels ? this.state.selectedVCWheels : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcWindowsContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCWindows ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Windows and Lights  {this.state.isYourDetailsClicked ? !this.state.selectedVCWindows ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of windshield, side & rear windows, headlights, taillights, and turn signals.</p>
                            <SellToWebRadioButtonComponent id="selectedVCWindows" name="rblWindows" data={this.windowsData} itemKey="value" value={this.state.selectedVCWindows ? this.state.selectedVCWindows : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcUpholsteryContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCUpholstery ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Upholstery and Odors {this.state.isYourDetailsClicked ? !this.state.selectedVCUpholstery ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of seats, carpet, and headliner.</p>
                            <SellToWebRadioButtonComponent id="selectedVCUpholstery" name="rblUpholstery" data={this.upholsteryData} itemKey="value" value={this.state.selectedVCUpholstery ? this.state.selectedVCUpholstery : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcInteriorContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCInterior ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Interior Equipment {this.state.isYourDetailsClicked ? !this.state.selectedVCInterior ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of dashboard, media, climate controls, and powered items.</p>
                            <SellToWebRadioButtonComponent id="selectedVCInterior" name="rblInterior" data={this.interiorData} itemKey="value" value={this.state.selectedVCInterior ? this.state.selectedVCInterior : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcMechanicalContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCMechanical ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Mechanical  {this.state.isYourDetailsClicked ? !this.state.selectedVCMechanical ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of engine, transmission, suspension, and brakes.</p>
                            <SellToWebRadioButtonComponent id="selectedVCMechanical" name="rblMechanical" data={this.mechanicalData} itemKey="value" value={this.state.selectedVCMechanical ? this.state.selectedVCMechanical : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcKeysContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCKeys ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Keys  {this.state.isYourDetailsClicked ? !this.state.selectedVCKeys ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Does your vehicle have at least 2 keys?</p>
                            <SellToWebRadioButtonComponent id="selectedVCKeys" name="rblKeys" data={this.keysData} itemKey="value" value={this.state.selectedVCKeys ? this.state.selectedVCKeys : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onYourDetailsClick} showSpinner={this.state.showSpinner} value="Your Details" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={this.state.vehicleDetailsData?.vin} makePlusModel={this.state.vehicleDetailsData?.makePlusModel}
                        imageUrl={this.state.vehicleDetailsData?.imageUrl} differentColorShown={this.state.vehicleDetailsData?.differentColorShown} odometer={CommonService.formatMoney(this.state.vehicleDetailsData?.odometer)}
                        engine={this.state.vehicleDetailsData?.selectedEngine ? this.state.vehicleDetailsData.selectedEngine.optionName : "--"}
                        transmission={this.state.vehicleDetailsData?.selectedTransmission ? this.state.vehicleDetailsData.selectedTransmission.optionName : "--"}
                        driveTrain={this.state.vehicleDetailsData?.selectedDriveTrain ? this.state.vehicleDetailsData.selectedDriveTrain.optionName : "--"}
                        colorBox={this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor.colorBox) : null}
                        colorName={this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor?.title ? this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor.title : this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor?.optionName}
                        trim={this.state.vehicleDetailsData?.selectedTrim ? this.state.vehicleDetailsData.selectedTrim.trimName : "--"}
                        siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                    />
                </aside>
            </section>
            <footer className="ac-site-footer ac-vehiclecondition-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}